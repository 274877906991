<template>
  <Layout
    :title="success ? $t('waterMark.finishTitle') : $t('waterMark.failTitle')"
  >
    <div :class="prefixCls">
      <!--   鉴定结果   -->
      <div :class="prefixCls + '__result'" v-if="success">
        <!--   音频展示   -->
        <h5 :class="prefixCls + '__payer_text'">
          {{ $t('waterMark.origin') }}
        </h5>
        <HaiPlayer :url="data.origin" :config="haiPlayerConfig1"></HaiPlayer>
        <h5 :class="prefixCls + '__payer_text'">
          {{ $t('waterMark.waterMark') }}
        </h5>
        <HaiPlayer :url="data.url" :config="haiPlayerConfig2"></HaiPlayer>
        <div :class="prefixCls + '__info'">
          <p :class="prefixCls + '__info_desc'">
            {{ $t('waterMark.watermarkResultDesc') }}
          </p>
          <div :class="prefixCls + '__info_download'" @click="handleDownload">
            <img src="../assets/images/download.png" :alt="$t('download')" />
            <span>{{ $t('download') }}</span>
          </div>
        </div>
      </div>
      <!--    无结果    -->
      <div :class="prefixCls + '__empty'" v-else>
        <h2>{{ $t('waterMark.failReason') }}</h2>
        <h5>{{ $t('waterMark.possibleReason') }}</h5>
        <p>{{ $t('waterMark.reason1') }}</p>
        <p>{{ $t('waterMark.reason2') }}</p>
      </div>
      <div :class="prefixCls + '__footer'">
        <HaiButton @click="$router.back()" v-if="!success">{{
          $t('tryAgain')
        }}</HaiButton>
        <HaiButton @click="$router.push({ path: '/' })">{{
          $t('backHome')
        }}</HaiButton>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import HaiButton from '@/components/HaiButton'
import HaiPlayer from '@/components/HaiPlayer'

const prefixCls = 'watermark-result-page'
export default {
  name: 'WatermarkResult',
  components: {
    Layout,
    HaiPlayer,
    HaiButton
  },

  data() {
    return {
      prefixCls,
      success: false,
      data: {
        origin: '',
        url: ''
      },
      haiPlayerConfig1: {
        waveformBg1: '#192d5f',
        waveformBg2: '#5391f9'
      },
      haiPlayerConfig2: {
        waveformBg1: '#1a2f60',
        waveformBg2: '#68E7ED',
        copy: true
      }
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      try {
        const data = JSON.parse(
          window.sessionStorage.getItem('watermark_result')
        )
        if (data.origin) {
          this.data = data
          this.success = true
        }
      } catch (e) {
        this.success = false
      }
    },
    handleDownload() {
      window.open(this.data.url, '_self')
    }
  }
}
</script>

<style lang="scss">
$prefixCls: 'watermark-result-page';
.#{$prefixCls} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &__result {
    width: 70%;
    margin-top: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .hai-player {
      width: 100%;
      margin-bottom: 60px;
      &:nth-child(1) {
        .hai-player__type {
          span {
            color: #5391f9;
            border-color: #5391f9;
          }
        }
      }
      &:nth-child(2) {
        .hai-player__type {
          span {
            color: #2e60b3;
            border-color: #2e60b3;
          }
        }
      }
    }
  }
  &__empty {
    width: 600px;
    margin-top: 170px;
    padding: 20px;
    color: #68e7ed;
    background-color: #143045;
    border-top: 1px #fff solid;
    h2 {
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    p,
    h5 {
      font-size: 16px;
      text-align: left;
      line-height: 32px;
    }
  }
  &__payer_text {
    width: 300px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    border: 1px solid rgba(83, 145, 249, 1);
    color: rgba(83, 145, 249, 1);
    font-size: 24px;
    display: inline-block;
    margin-bottom: 40px;
    &:nth-of-type(2) {
      border-color: #68e7ed;
      color: #68e7ed;
    }
  }
  &__info {
    width: 80%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    &_desc {
      font-size: 16px;
      line-height: 26px;
      color: rgba(255, 255, 255, 0.65);
      text-align: left;
    }
    &_download {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      > img {
        width: 34px;
        height: 35px;
        margin-right: 10px;
      }
      > span {
        height: 26px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(83, 145, 249, 1);
        line-height: 26px;
      }
    }
  }

  &__footer {
    margin-bottom: 70px;
    flex-shrink: 0;
    text-align: center;
    .hai-button:nth-child(1) {
      margin-right: 28px;
      span {
        color: #fff;
      }
    }
  }
}
</style>
